<template>
    <div class="book-detail">
      <p class="title">ISO14001标准解说</p>
      <div>
        <p class="ptittle">书本介绍</p>
        <span
          >本书系统的讲述了ISO14001标准的知识，从管理体系的基本概念、术语到管理体系的具体标准条款都有详细解说，是环境管理体系不可多得的教材类书籍。</span
        >
      </div>
      <br />
      <br />
      <div>
        <p class="ptittle">内容介绍</p>
        <span
          >为了在国内大力宣传ISO14001标准，帮助企业建立环境管理体系，中国标准出版社与我司共同策划了一套《ISO14001理论与实践丛书》，本书是其中之一。</span
        >
        <br />
        <br />
        <span>
            ISO14001《环境管理体系一一规范及使用指南》具有高度概括性、系统性的特点，这也决定了它在一定程度上的抽象性。企业要建立的是一个具体的环境管理体系，其依据自然是标准本身。尽管ISO14001：1996发布已近4年，但迄今为止大家对其理解仍有许多不同。本人在实际工作中也遇到一些矛盾的案例一一不同的认证机构对某些条款的要求竟然是两个方向。因此，正确理解标准是建立体系和认证的根本基础。
        </span>
      </div>
    </div>
  </template>
  
  <script>
  export default {};
  </script>
  
  <style lang="less" scoped>
  @import "../../../assets/style/BookStyle.css";
  </style>